import request from "../utils/https";

/**
 * 假期作业 列表
 * @param teacherId
 * @param moduleId
 * @param page
 * @param pageSize
 * @returns {*}
 */
export function getVacationHomeworkList(teacherId, moduleId, page, pageSize = 10) {
  return request({
    url: '/contentModule/getContentListByModuleId',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      teacherId,
      moduleId,
      page,
      pageSize
    }
  })
}

/**
 * 获取笔试/听说详情
 * @param id
 * @returns {*}
 */
export function getPaperQuestions(id) {
  return request({
    url: '/paperquestions/detail',
    method: 'POST',
    data: {
      id
    }
  })
}

/**
 * 课文/拓展 选择
 * @param id
 * @returns {*}
 */
export function repeatAfterSelect(id) {
  return request({
    url: '/repeatAfter/selectById',
    method: 'POST',
    data: {id}
  })
}


/**
 * 查询全部班级的作业统计数据
 * @param data
 * @return {*}
 */
export function getHomeworkStatistics(data) {
  return request({
    url: '/apiv2/teacher/statistics/getHomeworkStatistics',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 查询单个班级的作业统计数据
 * @param data
 * @return {*}
 */
export function getHomeworkStatisticsByClazzId(data) {
  return request({
    url: '/apiv2/teacher/statistics/getHomeworkStatisticsByClazzId',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 获取全班学生新趣味配音作业作答详情
 * @param data
 * @returns {*}
 */
export function getHomeworkDetails2dub(data) {
  return request({
    url: '/apiv2/teacher/homework/listVideoAnswer',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 获取学生新趣味配音作业作答详情
 * @param data
 * @returns {*}
 */
export function getVideoAnswerByStudent(data) {
  return request({
    url: '/apiv2/teacher/homework/getVideoAnswerByStudent',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 根据趣味配音id查询趣味配音试题详情
 * @param data
 * @returns {*}
 */
export function getBaseExerciseDetailForApp(data) {
  return request({
    url: '/apiv2/teacher/dub/getBaseExerciseDetailForApp',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 导出全部班级的作业统计数据
 * @param data
 * @return {*}
 */
export function exportHomeworkStatistics(data) {
  return request({
    url: '/apiv2/teacher/statistics/exportHomeworkStatistics',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
    data
  })
}

/**
 * 导出单个班级的作业统计数据
 * @param data
 * @return {*}
 */
export function exportHomeworkStatisticsByClazzId(data) {
  return request({
    url: '/apiv2/teacher/statistics/exportHomeworkStatisticsByClazzId',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob',
    data
  })
}


/**
 * 批量删除作业包
 * @param data
 * @return {*}
 */
export function batchDeleteHomeworkBag(data) {
  return request({
    url: '/apiv2/teacher/homework/batchDeleteHomeworkBag',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 我的布置 - 获取作业包列表
 * @param data
 * @return {*}
 */
export function findBagPageByCreator(data) {
  return request({
    url: '/apiv2/teacher/homework/findBagPageByCreator',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 我的布置 - 导出作业包
 * @param data
 * @return {*}
 */
export function exportBagByCreator(data) {
  return request({
    url: '/apiv2/teacher/homework/exportBagByCreator',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 按学校查看 - 获取作业包列表
 * @param data
 * @return {*}
 */
export function findBagPageBySchool(data) {
  return request({
    url: '/apiv2/teacher/homework/findBagPageBySchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 按学校查看 - 导出作业包
 * @param data
 * @returns {*}
 */
export function exportBagBySchool(data) {
  return request({
    url: '/apiv2/teacher/homework/exportBagBySchool',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 按教师查看 - 获取作业包列表
 * @param data
 * @return {*}
 */
export function findBagPageByTeacher(data) {
  return request({
    url: '/apiv2/teacher/homework/findBagPageByTeacher',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 按教师查看 - 导出作业包
 * @param data
 * @returns {AxiosPromise}
 */
export function exportBagByTeacher(data) {
  return request({
    url: '/apiv2/teacher/homework/exportBagByTeacher',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 获取作业包的数据
 * @param data
 * @return {*}
 */
export function bagDetail(data) {
  return request({
    url: '/apiv2/teacher/homework/bagDetail',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 批量发送作业包、模考的催交消息通知
 * @param data
 * @return {*}
 */
export function sentHomeworkBagNotice(data) {
  return request({
    url: '/apiv2/teacher/homework/sentHomeworkBagNotice',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 发送子作业的催交消息通知
 * @param data
 * @return {*}
 */
export function sentHomeworkItemNotice(data) {
  return request({
    url: '/apiv2/teacher/homework/sentHomeworkItemNotice',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

/**
 * 作业催交接口
 * @param data
 * @returns {*}
 */
export function urgeHomework(data = {}) {
  return request({
    url: '/apiv2/teacher/homework/urge',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
<template>
  <article>
    <div class="bg" @click="handleClose" v-show="visible"></div>
    <transition name="slide">
      <div class="wrapper" v-show="visible">
        <header class="header">
          <h1>查看未完成名单</h1>
          <div class="icon" @click="handleClose"></div>
        </header>
        <div class="title">
          <div class="col left">
            <span class="label">考试名称：</span>
            <span>{{name}}</span>
          </div>
          <div class="col middel">
            <span class="label">考试年级：</span>
            <span>{{gradeName}}</span>
          </div>
          <div class="col right">
            <span class="label">截止时间：</span>
            <span>{{dateEnd}}</span>
          </div>
        </div>
        <div class="container">
          <div class="label">未完成名单如下：</div>
          <div class="table">
            <ul class="tr th">
              <li class="td">学生姓名</li>
              <li class="td">班级名称</li>
              <li class="td">班级ID</li>
              <li class="td">年度</li>
              <li class="td">年级</li>
              <li class="td">教师名称</li>
              <li class="td">教师电话</li>
            </ul>
            <div class="table-content">
              <ul class="tr" v-for="item in list" :key="item.id">
                <li class="td">{{item.studentName}}</li>
                <li class="td">{{item.className}}</li>
                <li class="td">{{item.sequence}}</li>
                <li class="td">{{item.year}}</li>
                <li class="td">{{item.gradeName}}</li>
                <li class="td">{{item.teacherName}}</li>
                <li class="td">{{item.teacherPhone}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="foot">
          <el-button class="btn" type="primary" @click="exportList">导出未完成名单</el-button>
          <el-button class="btn" :loading="loading" @click="batchUrge">一键催交</el-button>
        </div>
      </div>
    </transition>
  </article>
</template>

<script>
import {sentHomeworkBagNotice} from "@/api/homework";

export default {
  name: 'UnfinishedDialog',
  props: ['bagId', 'examId', 'itemId', 'name', 'gradeName', 'dateEnd', 'list', 'visible', 'statusName'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    batchUrge () {
      if (this.statusName === '已结束') {
        this.$alert('作业已过期，请先修改作业截止时间，再进行打回操作。', '提示', {
          confirmButtonText: '好的'
        })
        return
      }
      this.loading = true
      const { list } = this
      const studentIds = list.map(item => item.id)
      const params = {
        studentIds,
        bagId: this.bagId,
        bagName: this.name,
        teacherId: this.userInfo.teacherId,
        teacherName: this.userInfo.teacherName,
        noticeCategory: 205
      }
      sentHomeworkBagNotice(params)
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.$message({
              message: '批量催交成功',
              type: 'success'
            })
          }
        })
        .catch(() => {
          this.loading = false
        })


      // for (let i = 0, len = list.length; i < len; i += 1) {
      //   userIdsArr.push(list[i].id)
      // }
      // const userIds = userIdsArr.join()
      // this.$axios({
      //   method: 'post',
      //   url: this.$urls.batchUrge,
      //   data: {
      //     userIds
      //   }
      // })
      //   .then((response) => {
      //     this.loading = false
      //     if (response.state === '11') {
      //       this.$message({
      //         message: '批量催交成功',
      //         type: 'success'
      //       })
      //     }
      //   })
      //   .catch(() => {
      //     this.loading = false
      //   })
    },
    exportList () {
      const { exportUnfinishedStudentList } = this.$urls
      const { examId } = this
      const { itemId } = this
      const url = examId ? `${exportUnfinishedStudentList}?id=${examId}` : `${exportUnfinishedStudentList}?itemId=${itemId}`
      // window.open(url)
      const downloadElement = document.createElement('a')
      downloadElement.href = url
      document.body.appendChild(downloadElement)
      downloadElement.click()
    },
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>

<style scoped lang="scss">
  .bg {
    background-color: rgba(0, 0, 0, .3);
    transition: all .3s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .wrapper {
    background-color: #fff;
    width: 1100px;
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    bottom: 0;
    z-index: 1000;
    .header {
      @include flex(space-between);
      height: 78px;
      padding: 0 28px;
      .icon {
        background-image: url("../../../assets/close.png");
        background-size: 100% 100%;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .container {
      padding: 0 28px;
      font-size: 17px;
    }
    .title {
      @include flex(flex-start);
      background-color: #F6F6F6;
      height: 54px;
      margin: 0 28px;
      border-radius: 8px;
      font-size: 17px;
      .col {
        flex-shrink: 0;
        width: 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .label {
          color: #999;
        }
      }
      .left {
        padding-left: 28px;
      }
      .middle {
        text-align: center;
      }
      .right {
        padding-right: 28px;
        text-align: right;
      }
    }
    .label {
      height: 44px;
      line-height: 44px;
    }
    .table {
      font-size: 15px;
      text-align: center;
      .tr {
        @include flex;
        height: 44px;
        .td {
          flex-shrink: 0;
          width: 14.28%;
          max-height: 44px;
          overflow: hidden;
        }
      }
      .th {
        background-color: #F6F6F6;
        color: #666666;
      }
      .table-content {
        overflow: auto;
        max-height: calc(90vh - 306px);
        border-left: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;
      }
    }
    .foot {
      @include flex;
      height: 84px;
      box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
      .btn {
        width: 180px;
        height: 46px;
        margin-right: 28px;
        font-size: 17px;
      }
    }
  }

</style>

<template>
  <div class="exam-statistics">
    <div class="column">
      <div class="left">
        <div class="line"></div>
        <div>总体情况</div>
      </div>
      <!--<div class="html-btn">-->
        <!--<div class="icon"></div>-->
        <!--<div>保存统计报告</div>-->
      <!--</div>-->
    </div>
    <div class="all-info">
      <div class="wrapper">
        <div class="box">
          <div class="img">
            <img src="../../../assets/exam/icon-1.png" alt="icon">
          </div>
          <div class="txt">
            <div>实考人数</div>
            <div class="num">{{count}}/{{totalCount}}</div>
            <div class="tips">缺考：{{totalCount - count}}人</div>
          </div>
        </div>
        <div class="box">
          <div class="img">
            <img src="../../../assets/exam/icon-2.png" alt="icon">
          </div>
          <div class="txt">
            <div>平均分</div>
            <div class="num">{{formatValue(avgScore)}}/{{totalScore}}</div>
            <div class="tips">仅统计已评分的考生</div>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="tr th">
          <div class="td">班级</div>
          <div class="td">考试人数</div>
          <div class="td">平均分</div>
          <div class="td">得分率</div>
          <div class="td">最高分</div>
          <div class="td">最低分</div>
          <div class="td">优秀率（ ≥85%）</div>
          <div class="td">低分率（&lt;60%）</div>
        </div>
        <div class="tr" v-for="(item, index) in countList" :key="index">
          <div class="td">{{item.clazzName}}</div>
          <div class="td">{{item.count}}</div>
          <div class="td">{{formatValue(item.avgScore)}}</div>
          <div class="td">{{formatValue(item.avgScoreRate, 1)}}%</div>
          <div class="td">{{formatValue(item.maxScore)}}</div>
          <div class="td">{{formatValue(item.minScore)}}</div>
          <div class="td">{{formatValue(item.excellentRate)}}%</div>
          <div class="td">{{formatValue(item.badRate)}}%</div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="left">
        <div class="line"></div>
        <div>分数段统计</div>
      </div>
    </div>
    <div class="row">
      <div class="left">
        <el-radio v-model="questionsType" :label="item.id" v-for="item in questionTypeArr" :key="item.id">{{item.name}}</el-radio>
      </div>
      <div class="tips">（点击蓝色柱状图可查看对应学生名单）</div>
    </div>
    <div class="echarts">
      <div>
        <!--<div ref="echarts" :style="{width: '1084px', height: '360px'}"></div>-->
        <div ref="echarts" :style="{width: '100%', height: '360px'}"></div>
      </div>
      <div class="name-str" v-if="nameStr">{{nameStr}}</div>
    </div>
    <div class="column">
      <div class="left">
        <div class="line"></div>
        <div>题型统计</div>
      </div>
    </div>
    <div class="table questionType">
      <div class="tr th">
        <div class="td">题型</div>
        <div class="td">平均分</div>
        <div class="td">得分率</div>
        <div class="td">最高分</div>
        <div class="td">最低分</div>
        <div class="td">优秀率（ ≥85%）</div>
        <div class="td">低分率（&lt;60%）</div>
      </div>
      <div class="tr" v-for="(item, index) in questionTypeStatisticsArr" :key="index">
        <div class="td">{{item.name}}</div>
        <div class="td">{{formatValue(item.avgScore)}}</div>
        <div class="td">{{formatValue(item.avgScoreRate)}}%</div>
        <div class="td">{{formatValue(item.maxScore)}}</div>
        <div class="td">{{formatValue(item.minScore)}}</div>
        <div class="td">{{formatValue(item.excellentRate)}}%</div>
        <div class="td">{{formatValue(item.badRate)}}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamStatistics',
  props: ['category', 'examId', 'itemId', 'classId', 'className'],
  data () {
    return {
      loading: false,
      show: false,
      width: 0,
      nameStr: null,
      avgScore: 0,
      totalScore: 0,
      count: 0,
      totalCount: 0,
      countList: [],
      questionTypeStatisticsArr: [],
      questionTypeArr: [],
      questionsType: 0,
      rangeArr: ['[0,10)', '[10,20)', '[20,30)', '[30,40)', '[40,50)', '[50,60)', '[60,70)', '[70,80)', '[80,90)', '[90,100]']
    }
  },
  watch: {
    questionsType () {
      this.scoreStatistics()
    },
    classId (val) {
      this.totalStatistics()
    }
  },
  methods: {
    totalStatistics () {
      const dataParams = {
        clazzId: this.classId
      }
      if (this.examId) {
        dataParams.examId = this.examId
      } else {
        dataParams.itemId = this.itemId
      }
      this.$axios({
        method: 'post',
        url: this.$urls.totalStatistics,
        data: dataParams
      })
        .then((response) => {
          if (response.state === '11') {
            const { data } = response
            this.avgScore = data.avgScore
            this.count = data.count
            this.countList = data.countList
            this.totalCount = data.totalCount
            this.totalScore = data.totalScore
            this.questionTypeStatistics()
          }
        })
    },
    questionTypeStatistics () {
      const dataParams = {
        clazzId: this.classId
      }
      if (this.examId) {
        dataParams.examId = this.examId
      } else {
        dataParams.itemId = this.itemId
      }
      this.$axios({
        method: 'post',
        url: this.$urls.questionTypeStatistics,
        data: dataParams
      })
        .then((response) => {
          if (response.state === '11') {
            console.log(178, response.data)
            this.questionTypeStatisticsArr = response.data
            this.getQuestionsType()
          }
        })
    },
    getQuestionsType () {
      const dataParams = {}
      if (this.examId) {
        dataParams.examId = this.examId
      } else {
        dataParams.itemId = this.itemId
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getQuestionsType,
        data: dataParams
      })
        .then((response) => {
          if (response.state === '11') {
            this.questionTypeArr = response.data
            this.scoreStatistics()
          }
        })
    },
    scoreStatistics () {
      const dataParams = {}
      if (this.examId) {
        dataParams.examId = this.examId
      } else {
        dataParams.itemId = this.itemId
      }
      dataParams.questionsType = this.questionsType || 0
      if (this.classId) dataParams.clazzId = this.classId
      this.$axios({
        method: 'post',
        url: this.$urls.scoreStatistics,
        data: dataParams
      })
        .then((response) => {
          if (response.state === '11') {
            const { data } = response
            const series = []
            const { allClazz } = data
            const nameStrArr = []
            if (allClazz) {
              const obj = {
                name: '全部班级',
                type: 'bar'
              }
              const studentRateArr = []
              const arr = []
              for (let i = 0, len = allClazz.length; i < len; i += 1) {
                studentRateArr.push(allClazz[i].studentRate)
                arr.push(allClazz[i].nameStr)
              }
              obj.data = studentRateArr
              series.push(obj)
              nameStrArr.push(arr)
            }
            const { oneClazz } = data
            if (oneClazz) {
              const obj = {
                name: this.className,
                type: 'bar',
                itemStyle: {
                  emphasis: {
                    color: '#1485E3'
                  }
                }
              }
              const studentRateArr = []
              const arr = []
              for (let i = 0, len = oneClazz.length; i < len; i += 1) {
                studentRateArr.push(oneClazz[i].studentRate)
                arr.push(oneClazz[i].nameStr)
              }
              obj.data = studentRateArr
              series.push(obj)
              nameStrArr.push(arr)
            }
            this.series = series
            this.nameStrArr = nameStrArr
            if (this.show) {
              this.setOption(series)
            } else {
              this.initCharts(series)
            }
          }
        })
    },
    setOption (series) {
      const { rangeArr } = this
      this.myChart.setOption({
        color: ['#D2D2D2', '#309AF2', '#4cabce', '#e5323e'],
        tooltip: {
          trigger: 'item',
          formatter (params) {
            return `${params.seriesName}<br />分数段${params.name}，人数${params.value}%`
          }
        },
        xAxis: {
          name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data: rangeArr
        },
        yAxis: {
          name: '人数占比',
          max: 100,
          min: 0
        },
        series
      }, true)
    },
    initCharts (series) {
      this.show = true
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      const { rangeArr } = this
      this.myChart.setOption({
        color: ['#D2D2D2', '#309AF2', '#4cabce', '#e5323e'],
        tooltip: {
          trigger: 'item',
          formatter (params) {
            return `${params.seriesName}<br />分数段${params.name}，人数${params.value}%`
          }
        },
        xAxis: {
          name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data: rangeArr
        },
        yAxis: {
          name: '人数占比',
          max: 100,
          min: 0
        },
        series
      }, true)
      this.myChart.on('click', (params) => {
        const { dataIndex } = params
        const { componentIndex } = params
        for (let i = 0, len = rangeArr.length; i < len; i += 1) {
          if (i !== dataIndex) {
            this.myChart.dispatchAction({
              type: 'downplay',
              seriesIndex: [0, 1],
              dataIndex: i
            })
          }
        }
        const { nameStrArr } = this
        if (nameStrArr[componentIndex][dataIndex]) {
          this.myChart.dispatchAction({
            type: 'highlight',
            color: '#fff',
            seriesIndex: componentIndex,
            dataIndex
          })
          this.nameStr = nameStrArr[componentIndex][dataIndex]
        } else {
          this.nameStr = ''
        }
      })
    },
    nameStrSelected (params) {
      const { nameStrArr } = this
      const { dataIndex } = params
      const { componentIndex } = params
      this.nameStr = nameStrArr[componentIndex][dataIndex]
    },
    // 格式化数值
    formatValue(val, precision = 1) {
      return (val || 0).toFixed(precision)
    }
  }
}
</script>

<style scoped lang="scss">
.exam-statistics{
  //background: #1DC060;
  //padding: 15px;
}
  .column {
    @include flex(space-between);
    height: 66px;
    color: #333;
    .left {
      @include flex;
      font-size: 17px;
      font-weight: bold;
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }
    .html-btn {
      @include flex;
      width: 162px;
      height: 34px;
      font-size: 15px;
      color: #999;
      margin-right: 28px;
      border: 1px solid #E5E5E5;
      border-radius: 17px;
      .icon {
        background-image: url("../../../assets/exam/html.png");
        background-size: 100% 100%;
        width: 26px;
        height: 26px;
        margin-right: 6px;
      }
    }
  }
  .all-info {
    padding: 10px 28px 28px;
    border-bottom: 10px solid #F6F6F6;
    .wrapper {
      @include flex(flex-start);
      margin-bottom: 20px;
      .box {
        overflow: hidden;
        min-width: 329px;
        padding: 14px;
        border: 1px solid #C8C8C8;
        border-radius: 8px;
        margin-right: 28px;
        .img {
          float: left;
          width: 120px;
          height: 112px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          padding-top: 6px;
          margin-left: 134px;
          color: #333333;
          font-size: 17px;
          .num {
            margin: 8px 0;
            font-size: 26px;
          }
          .tips {
            color: #999999;
          }
        }
      }
    }
  }
  .table {
    border: 1px solid #E5E5E5;
    border-top: none;
    .tr {
      display: flex;
      align-items: center;
      min-height: 48px;
      .td {
        flex: 1 0;
        text-align: center;
        color: #333333;
        font-size: 15px;
      }
    }
    .th {
      background-color: #F6F6F6;
      color: #666666;
    }
  }
  .row {
    @include flex(space-between);
    padding: 0 28px;
    .left {
      label {
        margin-right: 28px;
      }
    }
    .tips {
      font-size: 15px;
      color: #999999;
    }
  }
  .questionType {
    margin: 0 28px 28px;
  }
  .name-str {
    background-color: #F6F6F6;
    padding: 14px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    margin: 0 28px 20px;
    color: #333333;
    font-size: 17px;
    line-height: 24px;
  }
</style>

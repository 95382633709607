import { render, staticRenderFns } from "./ExamStatistics.vue?vue&type=template&id=0c08ae52&scoped=true"
import script from "./ExamStatistics.vue?vue&type=script&lang=js"
export * from "./ExamStatistics.vue?vue&type=script&lang=js"
import style0 from "./ExamStatistics.vue?vue&type=style&index=0&id=0c08ae52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c08ae52",
  null
  
)

export default component.exports